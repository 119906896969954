import React from "react";
import MapFull from "../common/components/MapFull";
import BackButton from "../common/components/BackButton";
import FloatingTopBar from "../components/FloatingTopBar";
import UserPosition from "../common/components/UserPosition";
import TimeLine from "../components/TimeLine";
import { Fab } from "@mui/material";
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Annotation } from "../common/classes/annotation";
import Annotate from "../common/dialogs/Annotate";
import AnnotationMarker from "../common/components/AnnotationMarker";
import { MapCameraChangedEvent, MapCameraProps } from "@vis.gl/react-google-maps";
import { useParams } from "react-router-dom";
import { gql, useMutation, useQuery } from "@apollo/client";
import { computeZoom } from "../common/utils/position";
import Loading from "../common/components/Loading";
import { and, whereNotDeleted } from "../common/utils/graphql";
import { IMAGE_DEM_TIFF, IMAGE_DSM_TIFF, IMAGE_DTM_TIFF, IMAGE_KEY, IMAGE_ORTHOPHOTO } from "../common/hooks/useCache";
import AnalysisLayer from "../common/components/AnalysisLayer";
import { db } from "../common/db/db";
import IndexSlider from "../common/components/IndexSlider";
import { getIndex, globalAvailableIndexes } from "../utils/indexes";
import { useScreenSize } from "../common/hooks/useScreenSize";

function Compare() {
  const [ latLngs, setLatLngs ] = React.useState<google.maps.LatLngLiteral[]>([]);
  const [ selectedRange, setSelectedRange ] = React.useState<[number, number] | undefined>(undefined);
  const [ min, setMin ] = React.useState<number | undefined>(0);
  const [ max, setMax ] = React.useState<number | undefined>(9000);
  const [ compareSelectedRange, setCompareSelectedRange ] = React.useState<[number, number] | undefined>(undefined);
  const [ compareMin, setCompareMin ] = React.useState<number | undefined>(undefined);
  const [ compareMax, setCompareMax ] = React.useState<number | undefined>(undefined);
  const [ cameraState, setCameraState ] = React.useState<MapCameraProps | undefined>();
  const [ annotateDialogOpen, setAnnotateDialogOpen ] = React.useState<boolean>(false);
  const [ clickLatLng, setClickLatLng ] = React.useState<google.maps.LatLngLiteral | null>(null);
  const [ showButtons, setShowButtons ] = React.useState<boolean>(true);
  const [ locked, setLocked ] = React.useState<boolean>(true);
  const [ annotations, setAnnotations ] = React.useState<Annotation[]>([]);
  const [ availableIndexes, setAvailableIndexes ] = React.useState<string[]>([]);
  const [ selectedIndex, setSelectedIndex ] = React.useState<string | undefined>();
  const [ currentAnalysis, setCurrentAnalysis ] = React.useState<{
    uuid: string,
    created_at: string,
  } | null>(null);
  const [ analysis, setAnalysis ] = React.useState<{
    uuid: string,
    created_at: string,
    agriculture_analysis_data: {
      orthophoto_png: string,
    },
  }[]>([]);
  const [ field, setField ] = React.useState<{
    id: number,
    uuid: string,
    name: string,
  } | null>(null);
  const [ defaultCenter, setDefaultCenter ] = React.useState<google.maps.LatLngLiteral | undefined>();
  const [ defaultZoom, setDefaultZoom ] = React.useState<number | undefined>();

  const url = new URL(window.location.href);
  const analysisUUID = url.searchParams.get('analysis');
  const indexName = url.searchParams.get('index');

  const { vertical } = useScreenSize();

  if (indexName && !selectedIndex) {
    setSelectedIndex(indexName);
  }

  const { fieldId, compareAnalysisID, compareIndex } = useParams();

  const { loading, error, data } = useQuery(gql(`
    query FieldDetails($fieldId: uuid!) {
      fields(where: {uuid: {_eq: $fieldId}}) {
        id
        uuid
        name
        analyses(where: `+and(whereNotDeleted() + '{type: {_eq: "agriculture"}}')+`, order_by: {created_at: desc}) {
          uuid
          created_at
          agriculture_analysis_data {
            orthophoto
            orthophoto_png
            index_data(order_by: {name: asc}) {
              name
              png
              tiff
            }
          }
        }
        lat_lngs(where: `+whereNotDeleted()+`, order_by: {id: asc}) {
          lat
          lng
        }
        annotations(where: `+whereNotDeleted()+`, order_by: {id: asc}) {
          uuid
          created_at
          annotation
          position(where: `+whereNotDeleted()+`, order_by: {id: asc}) {
            lat
            lng
          }
        }
      }
    }
  `), {
    variables: {
      fieldId,
    }
  });

  const [ deleteAnnotation ] = useMutation(gql(`
    mutation DeleteAnnotation($uuid: uuid!) {
      update_annotations(where: {uuid: {_eq: $uuid}}, _set: {deleted_at: "now()"}) {
        affected_rows
      }
    }
  `));

  const getImageFromIndex = (index?: string): IMAGE_KEY => {
    switch (index?.toLowerCase()) {
      case 'orthophoto':
        return IMAGE_ORTHOPHOTO;
      case 'dem':
        return IMAGE_DEM_TIFF;
      case 'dsm':
        return IMAGE_DSM_TIFF;
      case 'dtm':
        return IMAGE_DTM_TIFF;
      default:
        return IMAGE_ORTHOPHOTO;
    }
  }

  React.useEffect(() => {
    setSelectedRange(undefined);
  }, [selectedIndex]);

  React.useEffect(() => {
    setCompareSelectedRange(undefined);
  }, [compareIndex]);

  React.useEffect(() => {
    if (data) {
      setField(data.fields[0]);
      setAnnotations(data.fields[0].annotations.filter((a: any) => a.position.length > 0).map((a: any) => {
        return {
          uuid: a.uuid,
          date: new Date(a.created_at),
          position: { lat: a.position[0].lat, lng: a.position[0].lng },
          annotation: a.annotation,
          audioBlobs: [],
          pictureBlobs: [],
          attachments: [],
        } as Annotation;
      }));
      setAnalysis(data.fields[0].analyses);
      let currAnalysis: any | null;
      if (!currentAnalysis) {
        currAnalysis = data.fields[0].analyses.find((a: any) => a.uuid === analysisUUID) ?? data.fields[0].analyses[0];
        setCurrentAnalysis(currAnalysis);
        if (currAnalysis) {
          const url = new URL(window.location.href);
          url.searchParams.set('analysis', currAnalysis.uuid);
          window.history.replaceState({}, document.title, url.toString());
        }
      } else {
        currAnalysis = currentAnalysis;
      }

      const b = new google.maps.LatLngBounds();
      const tmpLatLngs: google.maps.LatLngLiteral[] = [];
      data.fields[0].lat_lngs.forEach((latLng: any) => {
        b.extend(new google.maps.LatLng(latLng.lat, latLng.lng));
        tmpLatLngs.push({ lat: latLng.lat, lng: latLng.lng });
      });
      setDefaultCenter(b.getCenter().toJSON());
      setDefaultZoom(computeZoom(b, window.innerWidth, window.innerHeight));
      setLatLngs(tmpLatLngs);

      db.fields.put({
        id: data.fields[0].uuid,
        bounds: b.toJSON(),
      }).then(() => {});

      const aIdx = [];
      if (data.fields[0].analyses.length > 0) {
        const tmp = currAnalysis.agriculture_analysis_data;
        if (tmp && tmp.length > 0 && (tmp[0].orthophoto || tmp[0].orthophoto_png)) {
          aIdx.push('Orthophoto');
        }
        if (tmp && tmp.length > 0 && tmp[0].index_data) {
          for (const idx of tmp[0].index_data) {
            aIdx.push(idx.name.toUpperCase());
          }
        }
      }
      setAvailableIndexes(aIdx);
    } else {
      setLatLngs([]);
      setField(null);
      setAnalysis([]);
      setCurrentAnalysis(null);
      setAvailableIndexes([]);
      setDefaultCenter(undefined);
      setDefaultZoom(undefined);
    }
  }, [data, currentAnalysis, analysisUUID]);

  const handleCameraChange = React.useCallback((ev: MapCameraChangedEvent) => {
    setCameraState(ev.detail);
  }, []);

  const onBoundsChange = (bounds: google.maps.LatLngBounds) => {
    if (!currentAnalysis) {
      return;
    }

    db.analyses.put({
      id: currentAnalysis.uuid,
      bounds: bounds.toJSON(),
    }).then(() => {});
  }

  const onAnnotateDialogClose = (annotation: Annotation | undefined) => {
    if (annotation && clickLatLng) {
      annotation.uuid = annotations.length + '';
      annotation.position = clickLatLng;
      annotation.date = new Date();
      setAnnotations([...annotations, annotation]);
    }
    setAnnotateDialogOpen(false);
  }

  if (!defaultCenter) {
    return (
      <React.Fragment>
        <Loading open />
      </React.Fragment>
    );
  }

  return (
    <div style={{
      width: '100%',
      height: '100%',
      display: vertical ? 'block' : 'flex',
    }}>
      <BackButton />
      <Annotate open={annotateDialogOpen} onClose={onAnnotateDialogClose} latLng={clickLatLng} fieldID={field?.id ?? 0} />
      <div style={{
        width: vertical ? '100%' : '50%',
        height: vertical ? '50%' : '100%',
        borderBottom: vertical ? '2px solid white' : 'none',
        borderRight: vertical ? 'none' : '2px solid white',
      }}>
        <MapFull
        defaultCenter={defaultCenter}
        defaultZoom={defaultZoom}
        center={ locked && cameraState ? cameraState.center : undefined }
        zoom={ locked && cameraState ? cameraState.zoom : undefined }
        onClick={(e) => {
          setClickLatLng(e.detail.latLng);
          setAnnotateDialogOpen(true);
        }}>
          { /* <Polygon path={latLngs} zIndex={-100} /> */ }
          { globalAvailableIndexes.map((gIndex) => {
            if (!currentAnalysis || selectedIndex !== gIndex.id || !gIndex.imageKey) {
              return null;
            }
            if (gIndex.id === 'Orthophoto') {
              return (
                <AnalysisLayer key={gIndex.id} analysisID={currentAnalysis.uuid} image={IMAGE_ORTHOPHOTO}
                  height={ vertical ? '50%' : '100%' } width={ vertical ? '100%' : '50%'}
                  onBoundsChange={onBoundsChange} rgb reversed />
              );
            }
            if (gIndex.id === 'None') {
              return null;
            }
            return (
              <AnalysisLayer key={gIndex.id} analysisID={currentAnalysis.uuid} image={gIndex.imageKey}
                height={ vertical ? '50%' : '100%' } width={ vertical ? '100%' : '50%'}
                onBoundsChange={onBoundsChange} setMin={setMin} setMax={setMax} clipMin={0}
                range={selectedRange} reversed />
            );
          })}
          { /* <Polyline path={latLngs} closed /> */ }
          { showButtons && <FloatingTopBar
            availableIndexes={availableIndexes}
            selectedIndex={getIndex(selectedIndex)}
            onIndexChange={(index) => {
              setSelectedIndex(index.id);
              if (index) {
                const url = new URL(window.location.href);
                url.searchParams.set('index', index.id);
                window.history.replaceState({}, document.title, url.toString());
              }
            }} /> }
          <UserPosition />
          { showButtons && <TimeLine
            analysis={analysis as any}
            currentAnalysis={currentAnalysis ?? undefined} onAnalysisSelected={(analysisUuid) => {
              setCurrentAnalysis(analysis.find((a) => a.uuid === analysisUuid) ?? null);
              if (analysisUuid) {
                const url = new URL(window.location.href);
                url.searchParams.set('analysis', analysisUuid);
                window.history.replaceState({}, document.title, url.toString());
              }
            }} /> }
          { annotations.map((annotation) => (
            <AnnotationMarker key={annotation.uuid} annotation={annotation} onDelete={() => {
              deleteAnnotation({
                variables: {
                  uuid: annotation.uuid,
                }
              }).then(() => {
                setAnnotations(annotations.filter((a) => a.uuid !== annotation.uuid));
              }).catch((e) => {
                console.error("Failed to delete annotation", e);
              });
            }} />
          ))}
          { selectedIndex !== 'Orthophoto' && selectedIndex !== 'GLB' && selectedIndex !== 'None' && <IndexSlider min={min} max={max} onSelectedRange={(range) => {
            setSelectedRange(range);
          }} reversed /> }
        </MapFull>
      </div>
      <div style={{
        width: vertical ? '100%' : '50%',
        height: vertical ? '50%' : '100%',
        borderTop: vertical ? '2px solid white' : 'none',
        borderLeft: vertical ? 'none' : '2px solid white',
      }}>
        <MapFull
        defaultCenter={defaultCenter}
        defaultZoom={defaultZoom}
        center={ locked && cameraState ? cameraState.center : undefined }
        zoom={ locked && cameraState ? cameraState.zoom : undefined }
        onCameraChanged={handleCameraChange}
        onClick={(e) => {
          setClickLatLng(e.detail.latLng);
          setAnnotateDialogOpen(true);
        }}>
          { /* <Polygon path={latLngs} zIndex={-100} /> */ }
          { compareAnalysisID && <AnalysisLayer
            analysisID={compareAnalysisID}
            image={getImageFromIndex(compareIndex)}
            height={ vertical ? '50%' : '100%' }
            width={ vertical ? '100%' : '50%'}
            top={ vertical ? '50%' : '0' }
            left={ vertical ? '0' : '50%' }
            rgb={compareIndex === 'Orthophoto'}
            onBoundsChange={onBoundsChange}
            setMin={setCompareMin}
            setMax={setCompareMax}
            clipMin={0}
            range={compareIndex === 'Orthophoto' ? undefined : compareSelectedRange}
            reversed /> }
          { /* <Polyline path={latLngs} closed /> */ }
          <UserPosition />
          { annotations.map((annotation) => (
            <AnnotationMarker key={annotation.uuid} annotation={annotation} onDelete={() => {
              deleteAnnotation({
                variables: {
                  uuid: annotation.uuid,
                }
              }).then(() => {
                setAnnotations(annotations.filter((a) => a.uuid !== annotation.uuid));
              }).catch((e) => {
                console.error("Failed to delete annotation", e);
              });
            }} />
          ))}
          { compareIndex !== 'Orthophoto' && selectedIndex !== 'GLB' && <IndexSlider min={compareMin} max={compareMax} onSelectedRange={(range) => {
            setCompareSelectedRange(range);
          }} reversed /> }
        </MapFull>
      </div>
      <Fab style={{
        position: 'absolute',
        bottom: 80,
        right: 10,
      }} onClick={() => setShowButtons(!showButtons)}>
        { showButtons ? <VisibilityIcon /> : <VisibilityOffIcon /> }
      </Fab>
      <Fab style={{
        position: 'absolute',
        bottom: 10,
        right: 10,
      }} color={locked ? 'primary' : 'default'} onClick={() => setLocked(!locked)}>
        { locked ? <LockIcon /> : <LockOpenIcon /> }
      </Fab>
    </div>
  );
}

export default Compare;
