import React from 'react';
import CardButton from '../common/components/CardButton';
import MapIcon from '@mui/icons-material/Map';
import CompareIcon from '@mui/icons-material/Compare';
import SelectIndex from '../common/dialogs/SelectIndex';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Index } from '../common/utils/indexes';
import { globalAvailableIndexes } from '../utils/indexes';
import useEmbed from '../common/hooks/useEmbed';
import { primary } from '../theme';

export interface FloatingTopBarProps {
  currentAnalysisUUID?: string;
  currentIndex?: string;
  showCompare?: boolean;
  selectedIndex?: Index;
  availableIndexes: string[];
  onIndexChange: (index: Index) => void;
}

function FloatingTopBar(props: FloatingTopBarProps) {
  const [ selectIndexDialogOpen, setSelectIndexDialogOpen ] = React.useState<boolean>(false);
  const [ filteredSelectedIndex, setFilteredSelectedIndex ] = React.useState<Index[]>([]);

  const style: React.CSSProperties = {
    position: 'absolute',
    top: 8,
    left: 75,
    right: 8,
    height: 55,
    display: 'flex',
    maxWidth: '100%',
    maxHeight: '100%',
  };

  const { t } = useTranslation();

  const navigate = useNavigate();
  const { appendParams } = useEmbed();

  React.useEffect(() => {
    let filteredIndexes: Index[] = [];
    for (const index of globalAvailableIndexes) {
      if (index.showAlways || props.availableIndexes.includes(index.id)) {
        filteredIndexes.push(index);
      }
    }
    setFilteredSelectedIndex(filteredIndexes);
  }, [props.availableIndexes]);

  const getText = (index: string | undefined) => {
    if (!index) {
      return t('Analysis');
    }
    if (index === globalAvailableIndexes[0].id) {
      return t('Analysis');
    }
    return t(index);
  }

  return (
    <React.Fragment>
      <div style={style}>
        <CardButton
        onClick={() => setSelectIndexDialogOpen(true)}
        icon={<MapIcon />}
        text={getText(props.selectedIndex?.name ?? props.selectedIndex?.id)}
        secondaryTextPosition='left'
        style={{
          height: '100%',
          width: props.showCompare ? 'calc(50% - 5px)' : '100%',
          marginRight: 10,
          backgroundColor: selectIndexDialogOpen ? primary : undefined,
          color: selectIndexDialogOpen ? 'white' : undefined,
        }}
        iconButtonStyle={{
          color: selectIndexDialogOpen ? 'white' : undefined
        }}
        />
        { props.showCompare && !props.selectedIndex?.noCompare && <CardButton
        onClick={() => {
          if (!props.currentAnalysisUUID || !props.currentIndex || props.currentIndex === globalAvailableIndexes[0].id) {
            return;
          }
          navigate(appendParams(`./compare/${props.currentAnalysisUUID}/${props.currentIndex}`));
        }}
        icon={<CompareIcon />}
        text={t('Compare')}
        style={{
          height: '100%',
          width: 'calc(50% - 5px)',
        }} /> }
      </div>
      <SelectIndex availableIndexes={filteredSelectedIndex} open={selectIndexDialogOpen} onClose={(index) => {
        if (index) {
          props.onIndexChange(index);
        }
        setSelectIndexDialogOpen(false);
      }} selected={props.selectedIndex?.id} />
    </React.Fragment>
  );
}

export default FloatingTopBar;
