import React from 'react';
import TimeLine from './TimeLine';
import CardButton from '../common/components/CardButton';
import { Stack } from '@mui/material';
import { t } from 'i18next';
import ActionsDialog from '../dialogs/ActionsDialog';
import { primary } from '../theme';
import useCache, { CONTOUR_LINES_DXF, CONTOUR_LINES_SHP, GLB_FILE, IMAGE_DSM_TIFF, IMAGE_DTM_TIFF, IMAGE_ORTHOPHOTO } from '../common/hooks/useCache';
import JSZip from 'jszip';

export interface FloatingBottomBarProps {
  analysis: {
    uuid: string,
    created_at: string,
    agriculture_analysis_data: {
      id: number,
      orthophoto_png: string,
      phase: string,
      index_data: {
        name: string,
        png: string,
        tiff: string,
      }[],
    }[],
  }[];
  currentAnalysis?: {
    uuid: string,
    created_at: string,
    agriculture_analysis_data: {
      id: number,
      phase: string,
    }[],
  };
  selectedIndex?: string;
  setCurrentAnalysis: (analysis: any) => void;
}

function FloatingBottomBar(props: FloatingBottomBarProps) {
  const [ actionsDialogOpen, setActionsDialogOpen ] = React.useState<boolean>(false);

  const { downloadPresigned } = useCache();

  const style: React.CSSProperties = {
    position: 'absolute',
    bottom: '25px',
    left: 75,
    right: 8,
    height: 55,
    display: 'flex',
    maxWidth: '100%',
    maxHeight: '100%',
  };

  const downloadMap = async () => {
    if (!props.currentAnalysis) return;

    let orthophoto: Blob | null = null;
    let dtm: Blob | null = null;
    let dsm: Blob | null = null; 
    let glb: Blob | null = null;
    let contour_lines_dxf: Blob | null = null;
    let contour_lines_shp: Blob | null = null;

    try {
      orthophoto = await downloadPresigned(props.currentAnalysis.uuid, IMAGE_ORTHOPHOTO);
    } catch (error) {
      console.error(error);
    }
    try {
      dtm = await downloadPresigned(props.currentAnalysis.uuid, IMAGE_DTM_TIFF);
    } catch (error) {
      console.error(error);
    }
    try {
      dsm = await downloadPresigned(props.currentAnalysis.uuid, IMAGE_DSM_TIFF);
    } catch (error) {
      console.error(error);
    }
    try {
      glb = await downloadPresigned(props.currentAnalysis.uuid, GLB_FILE);
    } catch (error) {
      console.error(error);
    }
    try {
      contour_lines_dxf = await downloadPresigned(props.currentAnalysis.uuid, CONTOUR_LINES_DXF);
    } catch (error) {
      console.error(error);
    }
    try {
      contour_lines_shp = await downloadPresigned(props.currentAnalysis.uuid, CONTOUR_LINES_SHP);
    } catch (error) {
      console.error(error);
    }

    const zip = new JSZip();
    orthophoto && zip.file('orthophoto.tif', orthophoto);
    dtm && zip.file('dtm.tif', dtm);
    dsm && zip.file('dsm.tif', dsm);
    glb && zip.file('model.glb', glb);
    contour_lines_dxf && zip.file('contour_lines.dxf', contour_lines_dxf);
    contour_lines_shp && zip.file('contour_lines.shp', contour_lines_shp);

    const blob = await zip.generateAsync({ type: 'blob' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${props.currentAnalysis.uuid}.zip`;
    a.click();

    URL.revokeObjectURL(url);
  };

  return (
    <React.Fragment>
      <Stack direction="row" spacing={1} style={style}>
        <TimeLine
          analysis={props.analysis as any}
          currentAnalysis={props.currentAnalysis}
          currentIndex={props.selectedIndex}
          onAnalysisSelected={(analysisUuid) => {
            props.setCurrentAnalysis(props.analysis.find((a) => a.uuid === analysisUuid) ?? null);
            if (analysisUuid) {
              const url = new URL(window.location.href);
              url.searchParams.set('analysis', analysisUuid);
              window.history.replaceState({}, document.title, url.toString());
            }
          }}
          contained
        />
        <CardButton
          text={t('Actions')}
          style={{
            width: 'calc((100% - 8px) / 2)',
            height: '100%',
            backgroundColor: actionsDialogOpen ? primary : undefined,
            color: actionsDialogOpen ? 'white' : undefined,
          }}
          iconButtonStyle={{
            color: actionsDialogOpen ? 'white' : undefined,
          }}
          showMore={!actionsDialogOpen}
          showLess={actionsDialogOpen}
          onClick={() => setActionsDialogOpen(true)}
        />
      </Stack>
      <ActionsDialog
        open={actionsDialogOpen}
        onClose={() => setActionsDialogOpen(false)}
        downloadMap={async () => {
          await downloadMap();
          setActionsDialogOpen(false);
        }}
      />
    </React.Fragment>
  );
}

export default FloatingBottomBar;
